//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {TheMask} from 'vue-the-mask'

export default {
  name: "PinCode",
  components: {TheMask},
  props: {
    id: String,
    error: Boolean,
    title: String,
    placeholder: String,
    value: String,
    setValue: Function,
    cleanErrors: Function,
    getPasswordError: Function,
    setPasswordError: Function
  },
}

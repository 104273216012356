import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store/index'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import VueFileAgent from 'vue-file-agent';
import VTooltip from 'v-tooltip';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueMask from 'v-mask';
import './assets/css/main.scss';

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueFileAgent);


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'bootstrap';
import './assets/css/style.css';


Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VueI18n);
Vue.use(VTooltip);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.use(VueRouter);
Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.yaml$/i);
  const messages = {};

  locales.keys().forEach(key => {
    const matched = key.match(/([a-z0-9]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  messages: loadLocaleMessages(),
  locale: 'ru',
});

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app');

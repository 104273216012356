//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from "@/components/main/Loader";
import DocMaterial from "@/components/view-material/DocMaterial";
import utils from "@/utils";
import DownloadIcon from "@/components/icons/DownloadIcon";
import SignCard from "@/components/documents/table/SignCard";
import MenuIcon from "@/components/icons/MenuIcon";
import {mapMutations, mapState} from "vuex";
import ArrowGreyIcon from "@/components/icons/ArrowGreyIcon";
import config from "/app.config";
import SendToRvrBtn from "@/components/documents/btns/SendToRvrBtn";
import RvrModal from "@/components/documents/modals/RvrModal";
import api from "@/api/api";
import notification from "@/notification/notification";
import ArrowLeft from "@/components/icons/ArrowLeft";

export default {
  name: "TheDocumentContent",
  components: {ArrowGreyIcon, SignCard, DownloadIcon, DocMaterial, Loader, MenuIcon, SendToRvrBtn, RvrModal, ArrowLeft},
  data() {
    return {
      docMaterialKey: 0,
      config,
      isRvrModal: false,
      document: null,
      showLoader: false,
    }
  },
  created() {
    this.getDocument()
  },
  props: {
    documentType: String,
    documentId: String,
    backLink: Object,
  },
  methods: {
    downloadDoc() {
      if (this.document.id) {
        utils.downloadDocument(this.document.id, this.documentType)
      }
    },
    forceRender() {
      this.docMaterialKey++
    },
    toggleRvrModal() {
      if (this.isRvrModal === true) {
        this.isRvrModal = false
        utils.removeModalOpenClass()
      } else {
        this.isRvrModal = true
        utils.addModalOpenClass()
      }
    },
    getDocument() {
      this.showLoader = true

      const request = api.getDoc(this.documentId, this.documentType)

      request.then(response => {

        if (response.data && response.data.error && response.data.errorStr) {
          notification.error(response.data.errorStr)
          return
        }

        if (response.data && response.data.result) {
          this.document = response.data.result
          return
        }

        notification.error(this.$t('document.not_found'))
      }).catch(() => {
        notification.error(this.$t('errors.undefined_error'))
      }).finally(() => {
        this.showLoader = false
      })
    },
    changeDocument(document) {
      document.preview = this.document.preview
      this.document = document
    },
    ...mapMutations([
      'toggleMobileDocContextMenu'
    ]),
  },
  computed: {
    ...mapState([
      'mobileDocContextMenu',
      'allowSendToTrudVsem'
    ]),
    allowSendToTrudVsemForWorker() {
      if (this.document != null) {
        return this.document.external && ((typeof this.document.workerIsBasicSigner === 'undefined') || this.document.workerIsBasicSigner === false);
      }
      return false
    },
  }
}

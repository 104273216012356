//
//
//
//
//
//

import TestItem from "./TestItem";

export default {
  components: { TestItem },
  data() {
    return {
      tests: this.$store.state.trialTests.CHILDREN.find(test => test.ID === this.$route.params.id)
      .CHILDREN.find(test => test.ID === this.$route.params.subsectionid)
      .CHILDREN.find(test => test.ID === this.$route.params.testid).CHILDREN,
    }
  }
}

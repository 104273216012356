//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '/app.config'
import DocMaterial from "../view-material/DocMaterial";
import { mapState, mapMutations } from 'vuex'
import ImageMaterial from "@/components/view-material/ImageMaterial";
import utils from '../../utils'
import PdfMaterial from "@/components/view-material/PdfMaterial";
import Loader from "@/components/main/Loader";
import ArrowIcon from "@/components/icons/ArrowIcon";
import MenuIcon from "@/components/icons/MenuIcon";
import DownloadIcon from "@/components/icons/DownloadIcon";
import ArrowGreyIcon from "@/components/icons/ArrowGreyIcon";
import ArrowLeft from "@/components/icons/ArrowLeft";

export default {
  name: "DocumentView",
  components: {Loader, PdfMaterial, ImageMaterial, DocMaterial, ArrowIcon, MenuIcon, DownloadIcon, ArrowGreyIcon, ArrowLeft},
  methods: {
    downloadDoc() {
      if (this.docParamsToDownload) {
        utils.downloadDocument(this.docParamsToDownload, this.setShowDocumentsLoader)
      }
    },
    destroyed() {
      this.setDocParamsToDownload(null)
    },
    isImageTypeScan(scanType) {
      return ['jpeg', 'jpg', 'png'].includes(scanType)
    },
    isPdfTypeScan(scanType) {
      return scanType === 'pdf'
    },
    isDocTypeScan(scanType) {
      return ['doc', 'docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(scanType)
    },
    isDocType (doc) {
      return this.getScanTypeByFormat(doc.FORMAT) === 'doc'
    },
    isPersonnelDocType (doc) {
      return this.getScanTypeByFormat(doc.FORMAT) === 'personnel'
    },
    isImageType (doc) {
      return this.getScanTypeByFormat(doc.FORMAT)=== 'image'
    },
    isSimpleType (doc) {
      return this.getScanTypeByFormat(doc.FORMAT) === 'simple'
    },
    isPdfType (doc) {
      return this.getScanTypeByFormat(doc.FORMAT) === 'pdf'
    },
    getScanTypeByFormat(format){
      if (this.isImageTypeScan(format)) {
        return 'image'
      }

      if (this.isPdfTypeScan(format)) {
        return 'pdf'
      }

      if (this.isDocTypeScan(format)) {
        return 'doc'
      }

      return ''
    },
    getScanLink(scan) {
      return scan.PREVIEW ? scan.PREVIEW : "";
    },
    docUrl(doc) {
      const link = this.getScanLink(doc)

      if (!link) {
        return  ''
      }

      return link
    },
    isAgreeView(doc) {
      return doc.name === 'agree-document'
    },
    nextDoc(){
      if (this.isNextDoc) {
        this.docNumber++;
      }
    },
    prevDoc(){
      if (this.isPrevDoc) {
        this.docNumber--;
      }
    },
    ...mapMutations([
        'setDocParamsToDownload',
        'setShowDocumentsLoader',
        'toggleMobileDocContextMenu'
    ])
  },
  data() {
    return {
      docNumber: 0,
      config
    }
  },
  beforeUpdate() {
    const btnWrap = document.querySelector('.mobile-context-menu-btn');

    if (this.mobileDocContextMenu && !btnWrap.classList.contains('background-darkblue')) {
      btnWrap.classList.add('background-darkblue')
    } else if (btnWrap.classList.contains('background-darkblue')) {
      btnWrap.classList.remove('background-darkblue');
    }
  },
  computed: {
    hasOneCopy() {
      return this.docs.length === 1
    },
    backLink() {
      if (this.$router.currentRoute.query && this.$router.currentRoute.query.type && this.$router.currentRoute.query.type === 'personnel') {
        return { name: 'personnel_documents'}
      }

      return { name: 'documents'}
    },
    type(doc) {
      const type = doc.type

      if (!type) {
        return  'doc'
      }

      return type
    },
    docs() {
      return this.$router.currentRoute.params.docs
    },
    docsCount() {
      return this.docs.length;
    },
    isNextDoc() {
      if (this.docNumber < this.docsCount - 1) {
        return true
      }
      return false
    },
    isPrevDoc() {
      if (this.docNumber > 0) {
        return true
      }
      return false
    },
    linkCurrentDoc() {
      //return process.env.VUE_APP_SITE_URL + this.docs[this.docNumber].PATH
      return this.docs[this.docNumber].PATH
    },
    ...mapState([
      'worker',
      'docParamsToDownload',
      'showDocumentsLoader',
      'mobileDocContextMenu'
    ]),

  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState} from "vuex";
import utils from "@/utils";

import CertificatesModal from "@/components/documents/modals/CertificatesModal";
import DescriptionSign from "@/components/documents/table/DescriptionSign";
import GobblerIcon from '@/components/icons/GobblerIcon';
import SignIcon from "@/components/icons/SignIcon";
import SignModal from "@/components/documents/modals/SignModal";
import SignPepModal from "@/components/documents/modals/SignPepModal";

export default {
  name: "SignCard",
  components: {CertificatesModal, SignModal, SignPepModal, SignIcon, DescriptionSign, GobblerIcon},
  props: {
    document: Object,
    documentType: String,
    isDocumentPage: Boolean,
    getDocuments: Function,
    changeDocument: Function,
    forceRender: Function,
    signRules: Object,
    documentDate: String,
  },
  data() {
    return {
      showSignModal: false,
      showSignPepModal: false,
      isCertificatesModal: false,
      tooltipVisible: false,
      tooltipMessage: this.$t('sign.sign_not_allowed_by_rules'),
    }
  },
  methods: {
    showSignersModal() {
      this.showSignModal = true
      utils.addModalOpenClass()
    },
    showToolTip() {
      if(this.canBeDs) {
        return;
      }
      this.tooltipVisible = true;
    },
    hideTooltip() {
      if(this.canBeDs) {
        return;
      }
      this.tooltipVisible = false;
    },
    hideSignersModal() {
      this.showSignModal = false
      utils.removeModalOpenClass()
    },
    showCertificatesModal() {
      if (!this.hasAvailableDsSlot) {
        return false
      }
      if(!this.canBeDs) {
        return
      }
      this.isCertificatesModal = true
      utils.addModalOpenClass()
    },
    hideCertificatesModal() {
      this.isCertificatesModal = false
      utils.removeModalOpenClass()

    },
    downloadDoc() {
      if (this.document.id) {
        utils.downloadDocument(this.document.id, this.documentType)
      }
    },
    today() {
      let today = new Date(),
      dd = String(today.getDate()).padStart(2, '0'),
      mm = String(today.getMonth() + 1).padStart(2, '0'),
      yyyy = today.getFullYear();

      return dd + '.' + mm + '.' + yyyy;
    },
    setTooltipMessage(mess) {
      this.tooltipMessage = mess
    },
    signPep() {
      if(this.isCertificatesModal) this.isCertificatesModal = false;
      this.showSignPepModal = true;
    },
    closePepModal(signed = false) {
      this.showSignPepModal = false;
      if (signed) this.getDocuments();
      utils.removeModalOpenClass();
    }
  },
  computed: {
    currentSigner() {
      const current = this.document.signers.find(signer => signer.workerId == (this.currentStudent.ID || this.worker.id))

      return current || ''
    },
    signedDate() {
      return this.isSigned && this.currentSigner && this.currentSigner.dsProcessedAt || ''
    },
    isSignedOnPaper() {
      return this.document.processed && !this.document.dsProcessed
    },
    isSigned() {
      return this.currentSigner && (this.currentSigner.dsProcessedAt || this.currentSigner.sign && this.currentSigner.sign.govKeyBlockedState);
    },
    isGKey() {
      return this.currentSigner.sign && this.currentSigner.sign.signatureType === 'govKey';
    },
    signType() {
      const signType = this.currentSigner && this.currentSigner.sign && this.currentSigner.sign.signatureType;
      return `${signType}Signed`;
    },
    signTypeTitle() {
      const signType = this.currentSigner && this.currentSigner.sign && this.currentSigner.sign.signatureType;
      let signTypeText;
      switch (signType) {
        case "ds":
          signTypeText = `${this.$t('sign.signed')} ${this.$t('sign.ds')}`;
          break;
        case "pep": 
          signTypeText = `${this.$t('sign.signed')} ${this.$t('sign.pep')}`;
          break;
        case "govKey": 
          if (this.currentSigner.dsProcessedAt)
            signTypeText = `${this.$t('sign.signed')} ${this.$t('sign.govKey')}`;
          else 
            signTypeText = this.currentSigner.sign.state;
          break;
        default:
          signTypeText = ''
      }
      return signTypeText;
    },
    canBeDs() {
      if(Object.getOwnPropertyDescriptor(this.signRules, this.document.type) === undefined) {
        return true;
      }

      let allow = false;
      if(this.document.isIAmResponsible) {
        allow = this.signRules[this.document.type].UF_SIGN_NEP_RESP || this.signRules[this.document.type].UF_SIGN_KEP_RESP || this.signRules[this.document.type].UF_SIGN_PEP_RESP || this.signRules[this.document.type].UF_SIGN_GKEY_RESP;
      } else {
        allow = this.signRules[this.document.type].UF_SIGN_NEP || this.signRules[this.document.type].UF_SIGN_KEP || this.signRules[this.document.type].UF_SIGN_PEP || this.signRules[this.document.type].UF_SIGN_GKEY;
      }

      if (allow === false || this.signRules[this.document.type].UF_SIGN_ONLY_DOC_DATE === false) {
          return allow;
      }

      if (this.documentDate !== this.today()) {
          this.setTooltipMessage(this.$t('sign.sing_allowed_only_date', {date: this.documentDate}));
          return false;
      }
      return true;
    },
    ...mapState([
      'hasAccessToSign',
      'currentStudent',
      'hasAvailableDsSlot',
      'worker',
    ])
  }
}

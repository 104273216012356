/*eslint-disable */
import * as Centrifuge from '../centrifuge/centrifuge.min'
import api from "../api/api";
import notification from "../notification/notification";
import config from '../../app.config'
/*eslint-disable */

const BASE_URL = config.siteUrl;

const configCentrifuge = {
  user: '',
  timestamp: '',
  url: '',
  token: '',
  SockJS: '',
};

export default {
  settings: configCentrifuge,
  subscribes: [],
  centrifuge: '',
  //userChannel$ = new BehaviorSubject<channelMessage>(null),
  //companyChannel$ = new BehaviorSubject<channelMessage>(null),
  //userAndCompanyChannel$ = new BehaviorSubject<channelMessage>(null),
  prefixChannelUser: 'user_',
  prefixChannelCompany: 'company_',
  nameChannelUser: '',
  nameChannelCompany: '',
  nameChannelUserAndCompany: '',

  subscribeChannel(message) {
    if (message && message.type === "message" && message.data && message.data.type === "success_deposit") {
      this.getInfo();
    }
  },

  getSettingsToCentrifugo() {
    return api.getCentrifugeSettings()
  },

  connect() {
    this.getSettingsToCentrifugo()
      .then((res) => {
        this.settings = res.data;
        this.centrifuge = new Centrifuge(this.settings);
        this.centrifuge.connect();
        this.nameChannelUser = [this.prefixChannelUser, this.settings.user].join('');
        this.subscribeToChannel(this.nameChannelUser, (message) => this.userChannal(message));

        this.centrifuge.on('connect', function() {

        });
        this.centrifuge.on('disconnect', function(context) {
          console.warn('disconnect client', context)
        });
        this.centrifuge.on('error', function(error) {
          console.error('error',error)
        });
      })
  },

  disconnect() {
    this.centrifuge && this.centrifuge.disconnect();
  },

  subscribeToChannel(channal, callback) {
    if (!this.centrifuge) return;
    if (this.findSubscribe(channal)) return;
    let subscribe = this.centrifuge.subscribe(channal, callback);
    this.subscribes.push({nameChannel: channal, subscribe: subscribe});
  },

  subscribeToChannelCompany(channal) {
    this.subscribeToChannel(channal, (message) => this.companyChannal(message));
    this.nameChannelUserAndCompany = [this.nameChannelUser, '_', channal].join('');
    this.subscribeToChannel(this.nameChannelUserAndCompany, (message) => this.userAndCompanyChannal(message));
  },

  unsubscribeToChannel(nameChannel) {
    let activeSub = this.findSubscribe(nameChannel);
    activeSub.unsubscribe();
    this.removeChannelToList(activeSub.nameChannel);
  },

  unsubscribeToChannels() {
    for(let sub of this.subscribes) {
      sub.subscribe.unsubscribe();
      this.removeChannelToList(sub.nameChannel);
    }
  },

  findSubscribe(nameChannel) {
    return this.subscribes.find((s) => s.nameChannel === nameChannel);
  },

  removeChannelToList(nameChannel) {
    let index = this.subscribes.findIndex((s) => s.nameChannel === nameChannel);
    if (index != -1) {
      this.subscribes.splice(index, 1);
    }
  },

  callButtonFunc(button) {
    switch (button.method) {
      /*case 'chooseCompany':
        return () => {
          this.webNoty.subscribeToChannelCompany('company_' + button.params.id);
          this.api.setCurrentCompany(button.params.id);
        }*/
      case  'downloadDocs':
        return () => { window.open(button.link, '_blank')};
      default:
        return () => {}
    }
  },

  userChannal(mess) {
    let messageToCentrifuge = mess.data;
    const message = messageToCentrifuge;
    let button = message.data && message.data.data && message.data.data.button;
    if (button) {
      button.timestamp = message.data.timestamp;
      button.link = `${BASE_URL + button.params.url}`;
      button.className = 'btn btn-primary btn-xs pull-right margin-bottom-15';
      button.cb = this.callButtonFunc(button).bind(this);

      notification.information(message.data.title, {
        button,
        name: button.name,
        className: button.className,
        cb: button.cb,
      }, true);
    } else {
      notification.information(message.data.title)
    }
  },

}
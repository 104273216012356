//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState} from "vuex";
import SignCard from "@/components/documents/table/SignCard";
import TwoUsersIcon from "@/components/icons/TwoUsersIcon";
import ActionsCard from "@/components/documents/table/ActionsCard";

export default {
  name: "DocumentsTable",
  components: {SignCard, TwoUsersIcon, ActionsCard},
  props: {
    documents: Array,
    getDocuments: Function,
    isDocuments: Boolean,
    isPersonnelDocuments: Boolean,
    total: Number,
    limitChoices: Array,
    changeDocument: Function,
    scroll: Function,
    setScroll: Function,
    signRules: Object
  },
  data() {
    return {
      showPreviewLoader: false,
      limit: 25,
      pageNumber: 1
    }
  },
  updated() {
    if(document.documentElement.clientWidth <= 978) {
      this.scroll()
      this.setScroll()
    }
  },
  methods: {
    showDocument(document) {
      this.showPreview([document.id])
      //this.setDocParamsToDownload(document)
    },
    /*async downloadDocument(document) {
      await utils.downloadDocument(document, this.setShowPreviewLoader)
    },*/

    setShowPreviewLoader(isShow) {
      this.previewLoader = isShow
    },

    showScansPreviews(docs) {
      this.$router.push({
        name: 'document-view',
        params: {
          docs,
        }
      })
    },
    countScans(scans) {
      return scans.length
    },
    setPageNumber(number) {
      this.pageNumber = number;
    },
    isCurrentPage(number) {
      if (this.pageNumber == number) {
        return true;
      }
      return false;
    },
    couldBeSigned(document) {
      if(document.regNum || !document.needRegNum) return true;
      return false;
    },
    getDocumentDate(date, factDate) {
      if (factDate == "") {
        return date;
      }
      return factDate;
    }
  },
  computed: {
    documentType() {
      return this.isPersonnelDocuments
        ? 'persDoc' : 'concreteDoc'
    },
    pageCount() {
      return Math.ceil(this.total/this.limit);
    },
    ...mapState([
      'showDocumentsLoader',
    ])
  }
}

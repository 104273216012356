//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/api/api";
import VueCountdown from '@chenfengyuan/vue-countdown';
import utils from "@/utils";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "CodeForm",
  components: {VueCountdown},
  props: {
    setError: Function,
    error: String,
    phone: String,
    pin: String,
    setShowLoader: Function,
    checkResponse: Function,
    setNeedCheckCode: Function,
    timeOut: Number
  },
  data() {
    return {
      isCodeSend: false,
      hash: '',
      code: '',
      showResendCode: false,
      timerValue: this.timeOut,
    }
  },
  computed: {
    confirmCode: {
      get() {
        return this.code
      },
      set(value) {
        this.code = value.replaceAll(/\D+/ig, '')
      }
    },
  },
  methods: {
    onCodeTimerEnd(){
      this.timerValue = 0
      this.showResendCode = true
    },
    padStart(value, length, chars) {
      return utils.padStart(value, length, chars)
    },
    onCodeKeypress($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
    onCodeInput($event){
      $event.target.value = $event.target.value.replace(/\D+/g, '')
    },
    getCode (e) {
      if (this.timerValue) {
        return
      }

      this.setShowLoader(true)
      e.preventDefault()

      const response = api.getCode({
        login: this.phone
      })

      response.then(async (response) => {
        this.checkResponse(response, true)

        if (response.data && response.data.result ) {
          if (response.data.result.hash) {
            this.hash = response.data.result.hash
            this.isCodeSend = true
            this.showResendCode = true
          } else if (response.data.result.timeout) {
            this.timerValue = response.data.result.timeout * 1000
          }
        }
      }).finally(() => {
        this.setShowLoader(false)
      });
    },
    loginWithCode(e) {
      this.setShowLoader(true)
      e.preventDefault()

      const params = {
        pin: this.pin,
        phone: this.phone,
        code: this.code,
        hash: this.hash,
      }

      const response = api.confirmLogin(params);

      response.then(async (response) => {
        this.checkResponse(response, true)

        if (response.data && response.data.result) {

          if (response.data.result.token) {
            this.setToken(response.data.result.token);
            await this.getUserAndStudents()
            await this.getStudentInfo()
            localStorage.edu_app_token = response.data.result.token;
            this.$router.push({
              name: 'courses'
            })
          } else if (response.data.result.auth_error) {
            this.setError(response.data.result.auth_error)
            this.setNeedCheckCode(false)
          }
        }
      }).finally(() => {
        this.setShowLoader(false)
      });
    },
    codeValid() {
        return utils.validateCode(this.code)
    },
    ...mapActions([
      'login',
      'getUserAndStudents',
      'getStudentInfo',
    ]),
    ...mapMutations([
      'setToken',
    ]),
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import utils from "@/utils";

export default {
  name: "Login",
  props: {
    error: Boolean,
    title: String,
    placeholder: String,
    value: String,
    setValue: Function,
    cleanErrors: Function,
    setError: Function,
    setLoginError: Function,
    getLoginError: Function
  },
  methods: {
    onInput (value) {
      let val = value.target.value
      if(typeof val === "string") {
        val = value.target.value
        if (val === "") {
          this.setLoginError(false)
        }
        val = val.replace(/(\s){2,}|(\(){2,}|(\)){2,}|(-){2,}/gi, '$1$2$3$4')
        if (val !== "" && !utils.validateEmail(val) && !utils.validatePhone(val)) {
          this.setLoginError(true)
        } else {
          this.setLoginError(false)
        }
        this.setValue(val)
        value.target.value = val
      }
    },
    onInputClick() {
      this.cleanErrors()
      // this.setValueAndSelectionStart(arg)
    },
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from "../../../app.config";
import TabLinks from "@/components/auth/TabLinks";
import FormField from "./FormField";
import StateMachine from "../../stateMachine";
import Loader from "../main/Loader";
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import api from "../../api/api";
import VueCountdown from '@chenfengyuan/vue-countdown';
import utils from "../../utils";
import {mapActions, mapMutations, mapState} from "vuex";
import AgreeCheckbox from "@/components/main/AgreeCheckbox";
import Login from "@/components/auth/fields/Login";
import PinCode from "@/components/auth/fields/Pincode";
import notification from "@/notification/notification";
import router from "@/router";

const STATE_EMPTY = 'empty'
const STATE_INIT = 'init'
const STATE_LOGIN_SET = 'login_set'
const STATE_BIRTHDAY_SET = 'birthday_set'
const STATE_PHONE_SET = 'phone_set'
const STATE_CODE_SET = 'code_set'
const STATE_PIN_SET = 'pin_set'

export default {
  name: "Registration",
  components: {PinCode, Login, AgreeCheckbox, Loader, FormField, TabLinks, VueCountdown},
  metaInfo() {
    return {
      title: this.title,
    }
  },
  created() {
    this.setState(STATE_EMPTY, true);
    this.initFastRegistrationChecker();
    // api.getAuthMethod()
    //     .then(res => {
    //       this.showLoader = false;
    //
    //       this.checkResponse(res)
    //
    //       if (res.data && res.data.result && res.data.result.send_timeout) {
    //         this.maxCodeTime = 1000 * res.data.result.send_timeout
    //       }
    //     })
    //     .catch(() => {
    //       this.showLoader = false;
    //     })
  },
  data: function () {
    return {
      hidePage: true,
      error: '',
      config,
      showLoader: false,
      title: this.$i18n.t('auth.title'),
      login: '',
      password: '',
      birthDate: '',
      phone: '',
      hash: '',
      code: '',
      pin: '',
      pinConfirm: '',
      loginError: false,
      passwordError: false,
      state: new StateMachine(STATE_INIT,
          {
            [STATE_EMPTY]: () => {
            },
            [STATE_INIT]: async () => {
            },
            [STATE_LOGIN_SET]: this.setLogin,
            [STATE_BIRTHDAY_SET]: this.setBirthday,
            [STATE_PHONE_SET]: this.setPhone,
            [STATE_CODE_SET]: this.setCode,
            [STATE_PIN_SET]: this.setPin,
          }
      ),
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      maxCodeTime: 60 * 1000,
      timerValue: 0,
      showResendCode: false,
      open: false,
      isAgree: false,
      agreeError: false
    }
  },
  methods: {
    async initFastRegistrationChecker() {
      const key = this.getHash();

      if (key) {
        await this.getAuthorityByKey(key).then((data) => {
          this.setToken("");
          localStorage.setItem("edu_app_token", "")
          if (data.data && data.data.error) {
            router.push({
              name: 'login'
            })
          }
          if (data.data && data.data.result && data.data.result.result &&
              data.data.result.result.id && data.data.result.result.pin) {
            const [id, pin] = [data.data.result.result.id, data.data.result.result.pin];

            api.register({
              id: id,
              pin: pin
            }).then((res) => {
              this.login = id;
              this.password = pin;
              this.checkResponse(res, () => {
                this.setState(STATE_INIT, true)
              })
              this.setState(STATE_LOGIN_SET, true)

            }).catch((error) => {
              notification.error(error.message);
              this.setState(STATE_INIT, true)
            })
          }
        }).catch((error) => {
          notification.error(error.message);
          this.setState(STATE_INIT, true)
        })
      } else {
        this.setState(STATE_INIT, true)
      }
    },
    async getAuthorityByKey(key) {
      const params = {
        hash: key
      }
      return await api.registerByHash(params);
    },
    getHash() {
      const queryParams = this.$route.query;
      return queryParams["hash"] ? queryParams["hash"] : null;
    },
    setPhoneValue(val) {
      this.phone = val
    },
    setLoginError(error) {
      this.loginError = error
    },
    getLoginError() {
      return this.loginError
    },
    setPasswordError(error) {
      this.passwordError = error
    },
    getPasswordError() {
      return this.passwordError
    },
    setPinValue(value) {
      this.pin = value
    },
    setPinConfirmValue(value) {
      this.pinConfirm = value
    },
    setShowLoader(value) {
      this.showLoader = Boolean(value)
    },
    setIsAgree(value) {
      this.isAgree = Boolean(value)
    },
    handleChange(evt) {
      this.value = evt.target.value;
      this.open = false;
    },
    setTimer() {
      this.timerValue = this.maxCodeTime
    },
    endTimer() {
      this.timerValue = 0
    },
    padStart(value, length, chars) {
      return utils.padStart(value, length, chars)
    },
    cleanErrors() {
      this.error = ''
    },
    onCodeTimerEnd() {
      this.timerValue = 0
      this.showResendCode = true
    },
    resendCode() {
      this.setPhone()
      this.code = ""
      this.setTimer()
    },
    setLoginState() {
      this.setState(STATE_LOGIN_SET, true)
    },
    onCodeKeypress($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
    onCodeInput($event) {
      $event.target.value = $event.target.value.replace(/\D+/g, '')
    },
    checkResponse(response, onError = null) {
      if (response.data && response.data.error) {
        response.data.errorStr ? this.setError(response.data.errorStr) : this.setError(this.$t('errors.undefined_error'))

        if (onError && (typeof onError === 'function')) {
          onError()
        }

        throw new Error('error')
      }

      if (!response.data || !response.data.result || (typeof response.data.result !== 'object') || Object.keys(response.data.result) <= 0) {
        this.setError(this.$t('errors.undefined_error'))

        if (onError && (typeof onError === 'function')) {
          onError()
        }

        throw new Error('error')
      }
    },

    async setLogin() {
      this.cleanErrors()

      if (!this.login) {
        this.setError(this.$t('registration.enter_login'))

        await this.setState(STATE_INIT, true)
        this.setShowLoader(false)
        throw  new Error('login empty')
      }
      if (!this.password) {
        this.setError(this.$t('registration.enter_password'))

        await this.setState(STATE_INIT, true)
        this.setShowLoader(false)
        throw  new Error('password empty')
      }

      const request = api.register({
        id: this.login,
        pin: this.password
      })

      await request.then(response => {
        localStorage.setItem(this.storageStudentIdKey, this.login)

        this.checkResponse(response, () => {
          this.setState(STATE_INIT, true)
        })
      }).finally(() => {
        this.setShowLoader(false)
      })
    },
    async setBirthday() {
      this.cleanErrors()

      if (!this.birthDate) {
        this.setError(this.$t('registration.enter_birth_date'))
        this.setShowLoader(false)
        await this.setState(STATE_LOGIN_SET, true)
        throw  new Error('birth date empty')
      }

      const request = api.register({
        id: this.login,
        pin: this.password,
        birthDate: this.birthDate
      })

      await request.then(response => {
        this.checkResponse(response, () => {
          this.setState(STATE_LOGIN_SET, true)
        })
      }).finally(() => {
        this.setShowLoader(false)
      })
    },
    async setPhone() {
      this.cleanErrors()
      this.showResendCode = false

      if (!this.phone) {
        this.setError(this.$t('registration.enter_phone'))
        // this.phone = this.phone.substr(2)
        this.setShowLoader(false)
        await this.setState(STATE_LOGIN_SET, true)
        throw  new Error('phone empty')
      }

      const request = api.setPhone({
        id: this.login,
        pin: this.password,
        phone: this.phone
      })

      await request.then(response => {
        this.checkResponse(response, () => {
          // this.phone = this.phone.substr(2)
          this.setState(STATE_LOGIN_SET, true)
        })

        this.setTimer()

        if (response.data && response.data.result && response.data.result.hash) {
          this.hash = response.data.result.hash
        } else {
          this.setError(this.$t('errors.undefined_error'))

          this.endTimer()
          this.phone = this.phone.substr(2)
          this.setState(STATE_LOGIN_SET, true)
          throw new Error('phone empty')
        }
      }).finally(() => {
        this.setShowLoader(false)
      })
    },
    async setCode() {
      this.cleanErrors()

      if (!this.code) {
        this.setError(this.$t('registration.enter_code'))
        await this.setState(STATE_PHONE_SET, true)
        this.setShowLoader(false)
        throw new Error('code empty')
      }

      const request = api.confirmRegistration({
        id: this.login,
        code: this.code,
        hash: this.hash
      })

      await request.then(async response => {
        this.checkResponse(response, () => {
          this.setState(STATE_PHONE_SET, true)
        })

        if (response.data && response.data.result && response.data.result.token) {
          this.setToken(response.data.result.token);
          localStorage.setItem(this.storageStudentIdKey, this.login)
          await this.getUserAndStudents()
          await this.getStudentInfo()
          localStorage.edu_app_token = response.data.result.token;

          this.$router.history.push({
            name: 'courses'
          })
        } else if (response.data && response.data.result && response.data.result.result) {
          // success
        } else {
          this.setError(this.$t('errors.undefined_error'))

          throw new Error('error')
        }
      }).finally(() => {
        this.setShowLoader(false)
      })
    },
    async setPin() {
      if (!this.pin) {
        this.setError(this.$t('registration.enter_pin'))
        await this.setState(STATE_CODE_SET, true)
        this.setShowLoader(false)
        throw new Error('pin empty')
      }

      const request = api.setPin({
        id: this.login,
        pin: this.pin,
        hash: this.hash,
        repeat_pin: this.pinConfirm
      })

      await request.then(async response => {
        this.checkResponse(response, () => {
          this.setState(STATE_CODE_SET, true)
        })

        if (response.data && response.data.result && response.data.result.token) {
          this.setToken(response.data.result.token);
          await this.getUserAndStudents()
          await this.getStudentInfo()
          localStorage.edu_app_token = response.data.result.token;

          this.$router.history.push({
            name: 'courses'
          })
        } else {
          this.setError(this.$t('registration.undefined_error'))

          throw new Error('error')
        }
      }).finally(() => {
        this.setShowLoader(false)
      })
    },
    setError(error) {
      this.error = error
    },
    setState(state, withoutCallback = false) {
      this.state.transition(state, withoutCallback)
    },
    setAgreeError(val) {
      this.agreeError = val;
    },
    ...mapMutations([
      'setToken'
    ]),
    ...mapActions([
      'getUserAndStudents',
      'getStudentInfo',
    ]),
  },
  computed: {
    message() {
      switch (this.state.state) {
        case STATE_INIT:
          return this.$t('registration.login_message')

          /*case STATE_LOGIN_SET:
            return this.$t('registration.birth_date_message')*/

        case STATE_LOGIN_SET:
          return this.$t('registration.phone_message')

        case STATE_PHONE_SET:
          return this.$t('registration.code_message') + ': ' + this.phone + ', ' + this.$t('registration.enter_code_below')

        case STATE_CODE_SET:
          return this.$t('registration.pin_your_number') + ' ' + this.phone + ' ' + this.$t('registration.pin_confirmed') +
              '<br>' + this.$t('registration.pin_message')
      }

      return ''
    },
    isInitState() {
      return this.state.is(STATE_INIT)
    },
    isLoginSetState() {
      return this.state.is(STATE_LOGIN_SET)
    },
    isBirtDaySetState() {
      return this.state.is(STATE_BIRTHDAY_SET)
    },
    isPhoneSetState() {
      return this.state.is(STATE_PHONE_SET)
    },
    isCodeSetState() {
      return this.state.is(STATE_CODE_SET)
    },
    isPinSetState() {
      return this.state.is(STATE_PIN_SET)
    },
    changeText() {
      if (utils.validateEmail(this.phone)) {
        return this.$t('registration.change_email')
      }

      return this.$t('registration.change_phone')
    },
    buttons() {
      return [
        {
          label: this.$t('registration.login_button'),
          click: async (e) => {
            e.preventDefault()
            if (!this.isAgree) {
              this.setAgreeError(true)
              return;
            }
            this.setShowLoader(true)
            this.setState(STATE_LOGIN_SET)
          },
          if: this.isInitState,
          disabled: false,
          class: {disabled: !this.isAgree}
        },
        /*{
          label: this.$t('registration.birth_date_button'),
          click: async (e) => {
            e.preventDefault()
            this.setShowLoader(true)
            this.setState(STATE_BIRTHDAY_SET)
          },
          if: this.isLoginSetState,
          disabled: false,
        },*/
        {
          label: this.$t('registration.phone_button'),
          click: async (e) => {
            e.preventDefault()
            if (this.getLoginError()) {
              this.setError(this.$t("errors.invalid_data_format"))
              return;
            }
            this.setShowLoader(true)
            this.setState(STATE_PHONE_SET)
          },
          if: this.isLoginSetState,
          disabled: false,
          class: {disabled: this.getLoginError()},
        },
        {
          label: this.$t('registration.code_button'),
          click: async (e) => {
            e.preventDefault()
            if (!utils.validateCode(this.code)) {
              this.setError(this.$t("errors.invalid_data_format"))
              return;
            }
            this.setShowLoader(true)
            this.setState(STATE_CODE_SET)
          },
          if: this.isPhoneSetState,
          disabled: false,
          class: {disabled: !utils.validateCode(this.code)},
        },
        {
          label: this.$t('registration.pin_button'),
          click: async (e) => {
            e.preventDefault()
            if (!utils.validatePin(this.pin, this.pinConfirm)) {
              this.setError(this.$t("errors.invalid_data_format"))
              return
            }
            this.setShowLoader(true)
            this.setState(STATE_PIN_SET)
          },
          if: this.isCodeSetState,
          disabled: false,
          class: {disabled: !utils.validatePin(this.pin, this.pinConfirm)},
        }
      ]
    },
    buttonsForState() {
      return this.buttons.filter(button => button.if)
    },
    isIdAuthMethod() {
      return this.authMethod === this.idAuthMethod
    },

    isPhoneAuthMethod() {
      return this.authMethod === this.phoneAuthMethod
    },

    confirmCode: {
      get() {
        return this.code
      },
      set(value) {
        this.code = value.replaceAll(/\D+/ig, '')
      }
    },

    ...mapState([
      'authMethod',
      'idAuthMethod',
      'phoneAuthMethod',
      'storageStudentIdKey',
    ]),
  }
}

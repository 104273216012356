import Noty from 'noty';
import ClipboardJS from 'clipboard';

export default {

  options: {
    dismissQueue: true,
    layout: 'bottomRight',
    theme: "metroui",
    closeWith: ['button', 'click'],
    timeout: 5000,
    progressBar: true,
    maxVisible: 5,
    modal: false
  },


  error(str) {

    this.options.text = str;
    this.options.type = "error";

    new Noty(this.options).show();
  },

  warning(str) {

    this.options.text = str;
    this.options.type = "warning";

    new Noty(this.options).show();
  },

  success(str) {

    this.options.text = str;
    this.options.type = "success";

    new Noty(this.options).show();
  },

  information(str, button = null) {
    this.options.text = str;
    this.options.type = "information";

    if (button) {
      let options = {...this.options};
      let n = new Noty(
        Object.assign(
          {},
          options,
          {
            buttons: [
              Noty.button(
                button.name,
                button.className,
                function () {
                  button.cb();
                  n.close();
                }),
              Noty.button('Скопировать ссылку', 'btn btn-primary btn-xs pull-right margin-bottom-15 copy-button-notification', () => {
              }, {id: 'copy-button-' + button.button.timestamp}),
            ],
            callbacks: {
              afterShow() {
                const cl = new ClipboardJS(document.getElementById('copy-button-' + button.button.timestamp), {
                  target: function () {
                    return document.getElementById('copy-button-' + button.button.timestamp);
                  },
                  text: function () {
                    return `${button.button.link}`;
                  }
                });
                cl.on('success', function (e) {
                  e.clearSelection();
                  cl.destroy();
                  n.close();
                  new Noty({...options, ...{text: 'Ссылка на архив скопирована в буфер обмена'}}).show()
                })
              }
            }

          }
        )
      ).show();

    } else {
      new Noty(this.options).show();
    }
  },
}

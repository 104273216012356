//
//
//
//
//
//
//
//
//
//

import MagnifyingGlassIcon from '@/components/icons/MagnifyingGlassIcon';

export default {
  props: ['value'],
  components: {
    MagnifyingGlassIcon
  },
}
